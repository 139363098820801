<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <img src="../assets/images/logo/logo.png" alt="" style="width: 200px">
      </b-link>

      <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-2">Login</b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation" >
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email">
                  <b-input-group>
                    <b-form-input
                            id="login-email" v-model="userEmail" :state="errors.length > 0 ? false:null"
                            name="login-email" placeholder="E-mail address"/>
                      <b-input-group-append>
                        <b-overlay variant="white" :show="showLoading" spinner-variant="primary" opacity=".75" rounded="sm">
                          <b-button style="height: 37.5px;border-bottom-left-radius: 0;border-top-left-radius: 0" variant="primary" size="sm" @click="sendCode">Send</b-button>
                        </b-overlay>
                      </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ emailError }}</small>
                </validation-provider>
              </b-form-group>

              <!-- verification code -->
              <b-form-group label="" label-for="code">
                <validation-provider #default="{ errors }" name="code" >
                  <b-form-input id="code" v-model="code" :state="errors.length > 0 ? false:null"
                          name="code" placeholder="Verification code with E-mail"/>
                  <small class="text-danger">{{ codeError}}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay variant="white" :show="showLoading2" spinner-variant="primary" opacity=".75" rounded="sm">
                <b-button type="submit" variant="primary" block @click="validationForm">Login</b-button>
              </b-overlay>

            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BOverlay,BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      code: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
      emailError:null,
      codeError:null,
      showLoading : false,
      showLoading2 : false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        //this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    sendCode() {
      let reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (reg.test(this.userEmail)) {
        this.emailError= "";
        this.showLoading = true;
        this.$http({
          method: 'post',
          url: this.GLOBAL.rootPath + '/customer/user/getEmailCode',
          data: $.param({email:this.userEmail}),
        }).then(res => {
          this.showLoading = false;
          if (res.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Send successful ',
                icon: 'UserIcon',
                variant: 'success',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Send Fail',
                icon: 'UserIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(error => {
          this.showLoading = false;
          alert(error);
        })
      } else {
        this.emailError= "The Email field must be a valid email";
      }
    },
    validationForm() {
      if (this.userEmail == null || this.code === ''){
        this.codeError = 'Please Enter Email and Code';
      }else {
        this.showLoading2 = true;
        this.$http({
          method: 'post',
          url: this.GLOBAL.rootPath + '/customer/user/emailLogin',
          data: $.param({email:this.userEmail,code:this.code}),
        }).then(res => {
          this.showLoading2 = false;
          if (res.data.result) {
            this.codeError = '';
            localStorage.setItem('contactId', [res.data.contact.contactId]);
            localStorage.setItem('username', [res.data.contact.email]);
            this.$router.push({path: '/'});
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login successful ',
                icon: 'UserIcon',
                variant: 'success',
              },
            });
          } else {
            this.codeError = res.data.msg;
          }
        }).catch(error => {
          this.showLoading2 = false;
          alert(error);
        })
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
